import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Star from "../../../images/star.png"

import "./categories.scss"
import { FETCH_CATEGORIES } from "../blog-actions/blog-actions"

const Categories = () => {
  const [category, setCategory] = useState([])
  useEffect(() => {
    FETCH_CATEGORIES(setCategory)
  }, [])
  return (
    <>
      {category.length > 0 && (
        <div className="categories-section mt-4 mb-5">
          <div className="categories d-flex align-items-center mb-4">
            <img src={Star} className="w-8" alt="wdn's pick star" />

            <span className="categories-title ml-2">Categories</span>
          </div>
          <ul>
            {category.map(data => {
              return (
                <li className="mb-2" key={data.slug}>
                  <Link to={`/blog/category/${data.slug}`}>{data.name}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

export default Categories
