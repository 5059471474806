// import axios from "../../../constants/axios"
import axios from "../../../constants/axios"
import { navigate } from "gatsby-link"

import endpoints from "../../../constants/endpoints"

export const FETCH_POSTS = async (dispatch, page, setLoading, slug) => {
  try {
    const pn = page || 1
    let res = { date: "" }
    if (!!slug) {
      res = await axios.get(
        process.env.GATSBY_WP_URL +
          endpoints.FETCH_CATEGORIES +
          `?slug=${slug}&_embed`
      )
      if (res.data.length === 0) {
        navigate("/404")
      }
      const meta = res?.data[0]?.acf?.seo_metadata[0]
      dispatch({ type: "META_DATA_LISTING", payload: meta })
      res = await axios.get(
        process.env.GATSBY_WP_URL +
          endpoints.FETCH_POSTS +
          `?page=${pn}&categories=${res.data[0].id}&_embed&per_page=10`
      )
    } else {
      res = await axios.get(
        process.env.GATSBY_WP_URL +
          endpoints.FETCH_POSTS +
          `?page=${pn}&_embed&per_page=10`
      )
    }
    if (res.status === 200) {
      dispatch({
        type: "BLOG_PAGINATION",
        payload: {
          total_pages: res?.headers["x-wp-totalpages"],
          total_posts: res?.headers["x-wp-total"],
        },
      })
      dispatch({
        type: "BLOG_POSTS",
        payload: res.data,
      })
      setLoading(false)
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      navigate("/blog")
    }
  }
}

export const FETCH_POSTS_PICKS = async dispatch => {
  try {
    const pn = 1
    let res = { date: "" }
    let slug = "wdn-picks"
    if (!!slug) {
      res = await axios.get(
        process.env.GATSBY_WP_URL +
          endpoints.FETCH_CATEGORIES +
          `?slug=${slug}&_embed`
      )

      res = await axios.get(
        process.env.GATSBY_WP_URL +
          endpoints.FETCH_POSTS +
          `?page=${pn}&categories=${res.data[0].id}&_embed&per_page=8`
      )
      if (res.status === 200) {
        dispatch({
          type: "BLOG_POSTS_PICKS",
          payload: res.data,
        })
      }
    }
  } catch (err) {}
}

export const FETCH_POST_DETAIL = async (slug, setLoading, setArticles) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_WP_URL + endpoints.FETCH_POSTS + `?slug=${slug}&_embed`
    )
    if (res.status === 200) {
      // if (res.data.length === 0) {
      //     navigate("/404")
      // }
      setArticles(res.data)
      setLoading(false)
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      navigate("/blog")
    }
  }
}

export const FETCH_CATEGORIES = async setCategory => {
  try {
    const res = await axios.get(
      process.env.GATSBY_WP_URL + endpoints.FETCH_CATEGORIES
    )
    if (res.status === 200) {
      const data = res.data.filter(data => parseInt(data.count) > 0)
      setCategory(data)
    }
  } catch (err) {}
}

export const FETCH_RELATED_ARTICLES = async (dispatch, categories, slug) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_WP_URL +
        endpoints.FETCH_POSTS +
        `?page=${1}&categories=${categories}&_embed`
    )
    if (res.status === 200) {
      const articles = res.data.filter(data => data.slug !== slug)
      dispatch({
        type: "RELATED_POSTS",
        payload: articles,
      })
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      navigate("/blog")
    }
  }
}
