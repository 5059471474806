import React from "react"

import Categories from "./categories/categories"
import WDNPicks from "./wdn-pick"

const RightSidebar = () => {
  return (
    <>
      <div>
        <WDNPicks />
      </div>
      <div>
        <Categories />
      </div>
    </>
  )
}

export default RightSidebar
