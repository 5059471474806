import React, { useEffect, useContext } from "react"

import { Col, Container, Row } from "react-bootstrap"

import { GlobalAuthContext } from "../../context/authContext"

import "./list.scss"
import SinglePost from "../acf-blocks/single-post/single-post"
import Paginator from "../paginator/paginator"
import Spinner from "../spinner/spinner"
import RightSidebar from "./right-sidebar"

const BlogListing = ({ page, handlePageChange }) => {
  const { state } = useContext(GlobalAuthContext)
  useEffect(() => {}, [state.blog_posts]) //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {state.blog_posts && state.blog_posts?.length > 0 ? (
        <div className="blog-list">
          <Container>
            <Row className="mb-md-1">
              <Col lg="8" className="pr-lg-5">
                {state.blog_posts.length > 0 && (
                  <Row>
                    {state.blog_posts.map((post, index) => {
                      return (
                        <Col md="6" key={index}>
                          <SinglePost post={post} />
                        </Col>
                      )
                    })}
                  </Row>
                )}
              </Col>
              <Col lg="4" className="order-12 order-lg-2">
                <RightSidebar />
              </Col>
              {parseInt(state?.blog_pagination?.total_pages) > 1 && (
                <Col className="order-1 order-lg-12 mt-3 mb-5">
                  <Paginator
                    pageCount={state?.blog_pagination?.total_pages}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={2}
                    setCurrentPage={handlePageChange}
                    currentPage={page - 1}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default BlogListing
