import React, { useContext, useEffect } from "react"

import { Link } from "gatsby"

import Star from "../../images/star.png"

import { GlobalAuthContext } from "../../context/authContext"
import { FETCH_POSTS_PICKS } from "./blog-actions/blog-actions"

import "./wdn-pick.scss"

const WDNPicks = () => {
  const { state, dispatch } = useContext(GlobalAuthContext)
  const picks = state.blog_posts_picks
  const getContent = value => {
    return {
      __html: value,
    }
  }
  useEffect(() => {
    if (!(picks?.length > 0)) {
      FETCH_POSTS_PICKS(dispatch)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {picks?.length > 0 && (
        <div className="wdn-picks border-bottom">
          <div className="title d-flex align-items-center mb-4">
            <img src={Star} className="w-8" alt="wdn's pick star" />
            <span className="wdn-pick-title ml-2">Popular Picks</span>
          </div>
          <ul className="list-of-picks">
            {picks.slice(0, 5).map(data => {
              return (
                <li className="picks mb-4" key={data.title.rendered}>
                  <Link to={`/blog/` + data?.slug}>
                    <span
                      dangerouslySetInnerHTML={getContent(
                        data?.title?.rendered
                      )}
                    ></span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

export default WDNPicks
