import React from "react"

import { Link } from "gatsby"

import "./landingPageFooter.scss"

export default function LandingPageFooter({ hasProfile, classes }) {
  return (
    <footer className={`wdn-new landing-page-footer ${classes}`}>
      <div className="lpf-content">
        {!hasProfile ? (
          <ul className="lpf-footer-menu d-flex  justify-content-center justify-content-lg-start gap-28px">
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/tac"> Terms & Conditions</Link>
            </li>
          </ul>
        ) : (
          ""
        )}
        <div className="text-center text-lg-left">
          <p>© {new Date().getFullYear()} WDN, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}
