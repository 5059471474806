import React, { useState, useEffect, useContext } from "react"

import queryString from "query-string"
import { navigate } from "gatsby-link"

import { FETCH_POSTS } from "./blog-actions/blog-actions"

import BlogListing from "./list"
import BreadcrumbComponent from "../breadcrumb/breadcrum"
import { GlobalAuthContext } from "../../context/authContext"
import Spinner from "../spinner/spinner"
import Layout from "../layout/layout"

const BlogListingPage = ({ location }) => {
  const [title, setTitle] = useState("Blog") // eslint-disable-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  const { dispatch } = useContext(GlobalAuthContext)
  const pages = [
    { link: "/", name: "Home" },
    { link: "/blog", name: "Blog" },
  ]
  const search = queryString.parse(location?.search)
  let slug = location?.pathname || ""
  slug = slug.split("/")
  slug = slug[3]
  const page = { link: slug, name: slug }

  const handlePageChange = page => {
    page += 1
    if (!!slug) {
      navigate(`/blog/category/${slug}?page=` + page)
    } else {
      navigate("/blog?page=" + page)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (!!slug) {
      FETCH_POSTS(dispatch, search.page, setLoading, slug)
    } else {
      FETCH_POSTS(dispatch, search.page, setLoading)
    }
  }, [search.page, slug]) // eslint-disable-line react-hooks/exhaustive-deps
  if (slug) {
    return (
      <Layout noVerification={true}>
        {!loading ? (
          <div className="front-page">
            <BreadcrumbComponent
              styles="mb-0"
              currentPage={page}
              pages={pages}
            />
            <BlogListing
              page={search.page}
              location={location}
              handlePageChange={handlePageChange}
              slug={slug}
            />
          </div>
        ) : (
          <Spinner />
        )}
      </Layout>
    )
  }
  return (
    <>
      {!loading ? (
        <BlogListing
          page={search.page}
          location={location}
          handlePageChange={handlePageChange}
          slug={slug}
        />
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default BlogListingPage
