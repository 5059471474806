import { Link } from "gatsby"
import React from "react"

import "./single-post.scss"

const SinglePost = ({ post, related }) => {
  const getContent = text => {
    return {
      __html: text,
    }
  }
  return (
    <div className="single-post w-100 mb-4">
      <div
        className={
          related
            ? "featured-image mb-2 related-section"
            : "featured-image mb-2"
        }
      >
        <Link to={`/blog/${post.slug}`}>
          <img
            src={post.featured_image_src?.full}
            alt={post?._embedded["wp:featuredmedia"][0]?.alt_text}
          />
        </Link>
      </div>
      <div className="title">
        <Link to={`/blog/${post.slug}`}>
          <h3
            className="mb-0"
            dangerouslySetInnerHTML={getContent(post?.title?.rendered)}
          ></h3>
        </Link>
      </div>
    </div>
  )
}

export default SinglePost
